<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
export default {
	name: "App",
	components: {
	},
	metaInfo: {
		title: "Yil Verdeja - Portfolio",
		titleTemplate: "%s | Yil Verdeja Portfolio"
	}
}
</script>

<style>
	/* HTML Elements */
body {
    font-family: 'Inconsolata', monospace;
    margin-top: 50px;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 10%;
}

h1, h2, h3 {
    font-family: 'Karla', sans-serif;
}

h1 {
    font-size: 3rem;
    font-weight: 700;
}

h2{
    font-weight: 400;
    font-size: 1.5rem;
    color: rgb(0, 17, 255);
}

h3 {
    font-weight: 700;
    font-size: 1.3rem;
}

a {
    color: black;
}

li, p {
    font-size: 1.1rem;
    line-height: 1.5;
}

/* Sections */
.body-section {
    margin: 30px 0;
}

/* Intro Section */
#intro h2{
    color: black;
}

#intro {
    height: calc(100vh - 180px);
    max-height: 100vh;
    min-height: 50vh;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
}

img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain
}

.profile-img {
    text-align: center;
    height: 30%;
    margin-bottom: 30px;
}

.profile-hello {
    text-align: center;
    margin-bottom: 1rem;
}

.profile-contact {
    padding-top: 20px;
}

.profile-available {
    text-align: center;
    color: white;
    padding: 10px;
}

/* Experience Section */
.row {
    padding: 10px 0 10px;
}

/* Main Projects Section  */
.cards-holder {
    width: 100%;
    margin: auto;
}

.pill-skill {
    margin: 4px 4px; 
    padding: 4px 4px; 
    font-weight: 400;
    font-size: 1rem;
}


/* Projects Section */

.project-links a {
    margin: 0px 5px;
}

.project-links {
    display: flex;
    justify-content: flex-end;
    margin: auto;
    padding: 1.5rem 0;
}

.main-project-links{
    display: flex;
    justify-content: space-evenly;
}

.btn-skill {
    margin: 5px 0;
    padding: 0 3px;
}

.btn-skill:first-child::first-line {
	margin-left: 0px; 
}

.btn-skill {
	margin-left: 5px;
}

.btn-skill:focus, .btn-skill:active {
    outline: none !important;
    box-shadow: none !important;
    border-style: outset !important;
}

.less-important-skills{
    display: inline;
}

.detail-btn {
    margin-bottom: 10px;
}

/* Footer Section */
#footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: white;
    height: 120px;
    z-index: 10;
}

.social-links {
    margin: 20px;
    
}

.link {
    margin: 0px 8px;
    color: rgb(0, 17, 255);
}

/* Media Queries */
@media screen and (max-width: 900px){
    body {
        margin-top: 10%;
        margin-bottom: 30%;
    }

}

@media screen and (max-width: 768px), screen and (max-height: 825px) {
    .profile-img {
        display: none;
        height: 0%;
    }
}

@media screen and (max-width: 1000px){
    .project-links {
        justify-content: flex-start;
        left: 10px;
    }
}

@media screen and (max-width: 450px), screen and (max-height: 580px), screen and (max-height: 750px) and (max-width: 900px){
    #intro {
        text-align: center;
    }
    .profile-intro .remove-mobile, .profile-contact .remove-mobile {
        display: none;
    }

    .profile-available {
        margin-bottom: 0px;
    }

    body {
        margin-left: 5%;
        margin-right: 5%;
    }
}

@media screen and (max-width: 450px){
    .title-section {
        text-align: center;
    }

    .less-important-skills{
        display: none;
    }
}
</style>