<template>
    <b-row class="project">
        <b-col lg="9">
            <h3 v-html="addAbbrDfn(title)"></h3>
            <p v-if="'start' in date && 'end' in date">{{ date.start }} - {{ date.end }}</p>
            <p v-else-if="'start' in date">{{ date.start }} - Present</p>
            <p v-else>{{ date.end }}</p>
            <b-badge pill variant="primary" v-for="(skill, i) in skills" :key="i" class="pill-skill">{{ skill }}</b-badge>
        </b-col>
        <b-col class="project-links" lg="3">
            <div v-for="(link, i) in links" :key="i">
                <b-link :href="link.link" target="_blank" rel="noopener noreferrer" :title="link.type">
                    <font-awesome-icon v-if="link.type == 'Report'" :icon="fileIcon" size="2x"></font-awesome-icon>
                    <font-awesome-icon v-else-if="link.type == 'Document'" :icon="invoiceIcon" size="2x"></font-awesome-icon>
                    <font-awesome-icon v-else-if="link.type == 'Video'" :icon="videoIcon" size="2x"></font-awesome-icon>
                    <font-awesome-icon v-else-if="link.type == 'Presentation'" :icon="powerpointIcon" size="2x"></font-awesome-icon>
                    <font-awesome-icon v-else-if="link.type == 'Website'" :icon="globeIcon" size="2x"></font-awesome-icon>
                    <font-awesome-icon v-else-if="link.type == 'Github'" :icon="githubIcon" size="2x"></font-awesome-icon>
                    <font-awesome-icon v-else-if="link.type == 'Instagram'" :icon="instagramIcon" size="2x"></font-awesome-icon>
                    <font-awesome-icon v-else-if="link.type == 'LinkedIn'" :icon="linkedinIcon" size="2x"></font-awesome-icon>
                    <font-awesome-icon v-else-if="link.type == 'Java'" :icon="javaIcon" size="2x"></font-awesome-icon>
                </b-link>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    data() {
        return {
            invoiceIcon: ['fas', 'file-invoice'],
            videoIcon: ['fas', 'video'],
            fileIcon: ['fas', 'file'],
            powerpointIcon: ['fas', 'file-powerpoint'],
            globeIcon: ['fas', 'globe'],
            githubIcon: ['fab', 'github'],
            instagramIcon: ['fab', 'instagram'],
            linkedinIcon: ['fab', 'linkedin'],
            javaIcon: ['fab', 'java']
        }
    },
    props: {
        title: String,
        date: Object,
        skills: Array,
        links: Array
    },
    methods: {
    }
}
</script>

<style scoped>

</style>