<template>
    <b-row>
        <b-col lg="3">
            <h3>{{ title }}</h3>
            <p v-if="'link' in company"><b-link  :href="company.link" target="_blank" rel="noopener noreferrer" style="text-decoration: none;">{{ company.name }}</b-link></p>
            <p v-else>{{ company.name }}</p>

            <p v-if="'start' in date && 'end' in date"><em>{{ date.start }} - {{ date.end }}</em></p>
            <p v-else-if="'start' in date"><em>{{ date.start }} - Present</em></p>
            <p v-else><em>{{ date.end }}</em></p>

            <b-button variant="primary" class="detail-btn" @click="toggleExperience()">
                <span v-if="toggle">See Details</span>
                <span v-else>Summarize</span>
            </b-button>
        </b-col>
        <b-col lg="9" class="experience-info">
            <div v-if="toggle" class="experience-summary">
                <p v-html="addAbbrDfn(summary)"></p>
                <b-badge pill variant="primary" v-for="(skill, i) in mainSkills" :key="i" class="pill-skill">{{ skill }}</b-badge>
                <div class="less-important-skills">
                    <b-badge pill variant="primary" v-for="(skill, i) in otherSkills" :key="i" class="pill-skill">{{ skill }}</b-badge>
                </div>
            </div>
            <div v-else class="experience-tasks">
                <ul>
                    <li v-for="(task, i) in tasks" :key="i">
                        <div v-html="addAbbrDfn(task[0])"></div>
                        <div v-if="task.length > 1" class="experience-detail">
                            <ul>
                                <!-- <li v-for="(sTask, si) in task.slice(1)" :key="si">{{ " " + sTask + " " }}</li> -->
                                <li v-for="(sTask, si) in task.slice(1)" :key="si" v-html="addAbbrDfn(sTask)"></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    data() {
        return {
            toggle: true
        }
    },
    props: {
        "title": String,
        "company": Object,
        "date": Object,
        "summary": String,
        "tasks": Array,
        "mainSkills": Array,
        "otherSkills": Array,
        "id": Number
    },
    methods: {
        toggleExperience() {
            this.toggle = !this.toggle;
        }
    },
}
</script>

<style scoped>

</style>