<template>
	<div>
		<section id="intro" class="intro">
			<div class="profile-img">
				<b-img id="myImg" src="images/profile/profile1.png" alt="Yil Verdeja Profile at Philips Internship" srcset=""></b-img>
			</div>
			
			<div class="profile-hello">
				<h1>Hi There!</h1>
			</div>

			<div class="profile-intro">
				<h2>My name is <strong>Yil Verdeja</strong> and I am a quirky <strong>Engineer</strong> who loves to explore the outdoors. <span class="remove-mobile"><span v-html="addAbbrDfn('I am a philomath with a peripatetic lifestyle')"></span> and I am involved with things that <strong>constantly challenge me</strong>.</span></h2>
			</div>
			
			<div class="profile-contact">
				<p>I am in Hong Kong! <span class="remove-mobile"><span v-html="addAbbrDfn('If you’d like to talk, get some boba, go on a hike or work on a project')"></span>, feel free to message me at <a href="mailto:yilverdeja@gmail.com">yilverdeja@gmail.com</a> or through <a href="https://www.linkedin.com/in/yilverdeja" target="_blank">linkedin</a>.</span> :)</p>
			</div>

			<div class="profile-available">
				<b-button variant="primary" href="resume/Yil_Verdeja_Resume_032021.pdf" target="_blank">I'm available!</b-button>
			</div>
		</section>

		<section id="background" class="body-section">
			<div class="title-section">
				<h2>Background</h2>
			</div>
			<Background :info="background"></Background>
		</section>

		<section id="experience" class="body-section">
			<div class="title-section">
				<h2>Work Experience</h2>
			</div>
			<div class="container">
				<Experience v-for="(e, i) in experiences" :key="i" :title="e.title" :company="e.company" :date="e.date" :summary="e.summary" :tasks="e.tasks" :mainSkills="e.mainSkills" :otherSkills="e.otherSkills" :id="i"></Experience>
			</div>
		</section>

		<section id="main-projects" class="body-section">
			<div class="title-section">
				<h2>Notable Projects</h2>
			</div>
			<div class="cards-holder">
				<div class="container">
					<MainProject v-for="(proj, i) in mainProjects" :key="i" :title="proj.title" :text="proj.text" :skills="proj.skills" :otherSkills="proj.otherSkills" :images="proj.images" :links="proj.links" :id="i"></MainProject>
				</div>
			</div>
		</section>

		<section id="projects" class="body-section">
			<div class="title-section">
				<h2>Other Projects</h2>
			</div>
			<div class="container">
				<SmallProject v-for="(proj, i) in smallProjects" :key="i" :title="proj.title" :date="proj.date" :skills="proj.skills" :links="proj.links"></SmallProject>
			</div>
		</section>

		<Footer></Footer>

	</div>
</template>

<script>
import Background from "@/components/Background.vue"
import Experience from "@/components/Experience.vue"
import MainProject from "@/components/MainProject.vue"
import SmallProject from "@/components/SmallProject.vue"
import Footer from "@/components/Footer.vue"

// json imports
import backgroundInfo from "@/assets/data/background.json"
import experienceInfo from "@/assets/data/experience.json"
import mainProjectsInfo from "@/assets/data/main_projects.json"
import smallProjectsInfo from "@/assets/data/small_projects.json"

export default {
	name: 'Home',
	data() {
		return {
			background: backgroundInfo,
			experiences: experienceInfo,
			mainProjects: mainProjectsInfo,
			smallProjects: smallProjectsInfo,
		}
	},
	mounted() {

		// TODO: add with bootstrap vue tooltips if possible. The tooltip function is from JQuery's UI Tooltip
		// document.querySelectorAll("[data-toggle='tooltip']").tooltip;
		// TODO: implement with JQuery - it's simpler
	},
	methods: {
	},
	metaInfo: {
		titleTemplate: null
	},
	components: {
		Background,
		Experience,
		MainProject,
		SmallProject,
		Footer
	}
}
</script>

<style scoped>

</style>