<template>
    <footer id="footer">
        <div class="social-links">
            <!-- Social Media Links -->
            <a class="link" href="https://www.instagram.com/yilverdeja" target="_blank" rel="noopener noreferrer" title="Instagram"><i class="fab fa-instagram fa-2x"></i></a>
            <a class="link" href="https://www.github.com/yilverdeja" target="_blank" rel="noopener noreferrer" title="Github"><i class="fab fa-github fa-2x"></i></a>
            <a class="link" href="https://www.linkedin.com/in/yilverdeja" target="_blank" rel="noopener noreferrer" title="LinkedIn"><i class="fab fa-linkedin fa-2x"></i></a>
            <a class="link" href="mailto:yilverdeja@gmail.com" title="Email"><i class="fas fa-envelope fa-2x"></i></a>

            <!-- Get Website Feedback -->
            <a class="link" href="https://github.com/yilverdeja/v1/issues/new?title=Feedback%20for%20your%20first%20website&body=Explain%20your%20issue%20here" target="_blank" title="Feedback"><i class="fas fa-comment-dots fa-2x"></i></a>
        </div>
        <div>
            <p>&copy; Yil Verdeja <span>{{ getFooterYear }}</span>, Designed by <a href="https://yve.life" target="_blank" rel="noopener noreferrer">yve.</a></p>
        </div>
    </footer>
</template>

<script>
export default {
    // TODO: import links
    data() {
        return {

        }
    },
    computed: {
        getFooterYear(){
            return new Date().getFullYear();
        }
    }
}
</script>

<style scoped>

</style>