<template>
    <b-container>
        <b-row v-for="k in Object.keys(info)" :key="k">
            <div class="col-lg-3">
                <h3>{{ capitalizeFirstLetter(k) }}</h3>
            </div>
            <div class="col-lg-9">
                <div v-for="(i, index) in info[k]" :key="index">
                    <div v-if="i.length > 1">
                        <p v-html="addAbbrDfn(i.join(breakLine))"></p>
                    </div>
                    <div v-else>
                        <p v-html="addAbbrDfn(i[0])"></p>
                    </div>
                </div>
            </div>
        </b-row>
    </b-container>
</template>

<script>
export default {
    data() {
        return {
            breakLine: " <br>",
        }
    },
    props: {
        info: Object
    },
    methods: {
        capitalizeFirstLetter(str) {
            let newStr = str[0].toUpperCase() + str.slice(1);
            return newStr;
        }
    }
}
</script>

<style scoped>

</style>