import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag';

import { library } from '@fortawesome/fontawesome-svg-core'
import {faGithub, faInstagram, faLinkedin, faJava} from "@fortawesome/free-brands-svg-icons"
// import {faEnvelope, faCommentDots} from "@fortawesome/free-regular-svg-icons"
import {faVideo, faFile, faFilePowerpoint, faFileInvoice, faGlobe, faEnvelope, faCommentDots} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import router from './router'

library.add(faGithub, faInstagram, faLinkedin, faJava, faEnvelope, faCommentDots, faFilePowerpoint, faFile, faFileInvoice, faVideo, faGlobe)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(BootstrapVue)
Vue.use(VueMeta)
Vue.use(VueGtag, {
  config: {id: "G-5W5DYGLJ7S"}
});

Vue.config.productionTip = false

import def from "@/assets/data/definitions.json"

Vue.mixin({
  methods: {
    addAbbrDfn(text) {
		let newText = text
		for (const [key, value] of Object.entries(def.abbr)) {
			let re = new RegExp(`\\b${key}\\b`, "g")
			let wrap = "<abbr title='"+ value +"'>"+ key +"</abbr>"
			newText = newText.replace(re, wrap);
		}
		for (const [key, value] of Object.entries(def.dfn)) {
			let re = new RegExp(`\\b${key}\\b`, "g")
			let wrap = "<dfn title='"+ value +"'>"+ key +"</dfn>"
			newText = newText.replace(re, wrap);
		}
		return newText
    }
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')