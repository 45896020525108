<template>
    <b-row>
        <b-col cols="12" class="mb-4">
            <b-card class="shawdow-sm h-100" img-top no-body>
                <b-carousel no-animation controls indicators>
                    <b-carousel-slide v-for="(img, i) in images" :key="i" :img-src="img"></b-carousel-slide>
                </b-carousel>

                <b-card-body>
                    <b-card-title class="card-title">{{ title }}</b-card-title>
                    <b-card-text v-html="addAbbrDfn(text)"></b-card-text>
                    <b-badge pill variant="primary" v-for="(skill, i) in skills" :key="i" class="pill-skill">{{ skill }}</b-badge>
                    <div class="less-important-skills">
                        <b-badge pill variant="primary" v-for="(oskill, i) in otherSkills" :key="i" class="pill-skill">{{ oskill }}</b-badge>
                    </div>
                </b-card-body>
                <b-card-footer class="main-project-links">
                    <div v-for="(link, i) in links" :key="i">
                        <b-link :href="link.link" target="_blank" rel="noopener noreferrer" :title="link.type">
                            <font-awesome-icon v-if="link.type == 'Report'" :icon="fileIcon" size="2x"></font-awesome-icon>
                            <font-awesome-icon v-else-if="link.type == 'Document'" :icon="invoiceIcon" size="2x"></font-awesome-icon>
                            <font-awesome-icon v-else-if="link.type == 'Video'" :icon="videoIcon" size="2x"></font-awesome-icon>
                            <font-awesome-icon v-else-if="link.type == 'Presentation'" :icon="powerpointIcon" size="2x"></font-awesome-icon>
                        </b-link>
                    </div>
                </b-card-footer>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
export default {
    data() {
        return {
            invoiceIcon: ['fas', 'file-invoice'],
            videoIcon: ['fas', 'video'],
            fileIcon: ['fas', 'file'],
            powerpointIcon: ['fas', 'file-powerpoint']
        }
    },
    props: {
        "title": String,
        "text": String,
        "skills": Array,
        "otherSkills": Array,
        "images": Array,
        "links": Array,
        "id": Number
    },
    methods: {
    }
}
</script>

<style scoped>
    .card-title {
        font-family: 'Karla', sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
    }
</style>